<template>
  <div id="contactUs">
    <img class="banner" :src="banner" alt="">
    <div class="infoWrap">
        <h2 class="infoTitle">{{info.name}}</h2>
        <p class="line"></p>
        <div class="phoneWrap">
            <img src="@/assets/phone.png" alt="">
            <div class="phone">
                <p class="title">全国服务热线 </p>
                <p class="phoneNumber">{{info.phone}}</p>
            </div>
        </div>
        <p class="describe">邮箱：{{info.email}}</p>
        <p class="describe">地址：{{info.address}}</p>
        <img class="describeImg" src="@/assets/contactUs.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'contactUs',
  data:function() {
  return {
      banner:'',//banner
      info:{}
    };
  },
  mounted() {
    this.$post('/api/pc/common/get/banner')
      .then((res) => { // 获取banner
        this.banner = res.contactBannerUrl;
        console.log(res)
      })
    this.$post('/api/pc/common/get/company/info')
      .then((res) => { // 获取banner
        console.log(res)
        this.info = res;
      })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='less' scoped>
    // #contactUs{
    //   width: 100%;
    //   overflow: hidden;
    // }
    .banner{
        min-height: 500px;
        background: #DBDCE0;
        display: block;
        width: 100%;
    }
    .infoWrap{
        margin: 1.91rem 0.69rem;
        color: #333333;
        .infoTitle{         
            font-size: 0.94rem;
            margin-bottom: 0.94rem;
        }
        .line{
            width: 2.91rem;
            height: 0.13rem;
            background: linear-gradient(90deg, #0C51A3, #269CD9);
        }
        .phoneWrap{
            margin: 1.75rem 0.63rem 1.06rem;
            display: flex;
            img{
                width: 2.34rem;
                height: 2.25rem;
                margin-right:0.63rem
            }
            .phone{
                .title{
                    font-size: 0.56rem;
                }
                .phoneNumber{
                    font-size: 1.13rem;
                }
            }
        }
        .describe{
            font-size: 0.56rem;
            margin-bottom: 0.5rem;
        }
        .describeImg{
            height: 12.87rem;
            margin-top: 1.8rem;
        }
    }
</style>
