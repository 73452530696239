<template>
  <div id="health" :style="imgBanner">
    <div class="banner">
      <myVideo class="video" v-if="info.videoUrl" :videoSrc='info.videoUrl' :posterUrl='info.coverUrl'></myVideo>
    </div>

    <!-- 解决方案 -->
    <div class="programmeWrap">
      <div class="titleWrap">
        <h2>解决方案</h2>
      </div>

      <img class="programmeImg" src="@/assets/solutionUrl.png" alt="">
      <!-- <img class="programmeImg" :src="info.solutionUrl" alt=""> -->
      <p class="content">{{info.solutionInstruction}}</p>
    </div>

    <!-- 产品介绍 -->
    <div class="productWrap">
      <div class="titleWrap">
        <h2>产品介绍</h2>
      </div>
      <div class="productList">
        <div class="item" v-for="item of info.detail" :key="item.id" @click="jumpType(item.jumpUrl,id)" :style="{backgroundImage:'url('+item.imgUrl+')'}">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myVideo from '../components/assembly/video.vue'
export default {
  name: 'Health',
  data() {
    return {
      info: {},
      imgBanner: {
        backgroundImage: 'url(' + require('../assets/healthBg.png') + ')',
      },
    }
  },
  components: {
    myVideo,
  },
  mounted() {
    this.$post('/api/pc/health/manage/get').then((res) => {
      // 获取智慧健康管理
      this.info = res
      console.log(res)
    })
  },
  methods: {
    jumpType(url, id) {
      if (url) {
        window.location = url
        return
      }
      this.$router.push({
        //核心语句
        path: '/productCategory', //跳转的路径
        query: { id },
      })
    },
  },
}
</script>
<style lang='less' scoped>
p,
h2 {
  margin: 0;
}
#health {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
}
.banner {
  // height: 550px;
  padding-top: 164px;
  background-size: 100% 100%;
  .video {
    margin: 0 20px;
  }
}
.programmeWrap {
  .titleWrap {
    display: flex;
    margin: 105px 23px 53px;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 48px;
    }
  }
  .programmeImg {
    display: block;
    width: calc(100% - 40px);
    height: auto;
    padding: 0 20px;
  }
  .content {
    margin: 84px 21px 94px;
    text-align: justify;
    font-size: 36px;
    line-height: 1.5;
  }
}
.productWrap {
  .titleWrap {
    display: flex;
    margin: 0 23px;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 48px;
    }
    img {
      width: 220px;
      height: 18px;
    }
  }
  .productList {
    margin: 41px 95px 0;
    padding-bottom: 155px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item {
      margin: 50px;
      width: 560px;
      height: 390px;
      border-radius: 20px;
      background: #fff;
      cursor: pointer;
      background-size: 100% 100%;
    }
  }
}
</style>