<template>
  <div id="hospital">
    <div class="banner">
      <img :src="info.solutionUrl" alt="">
    </div>
    <div class="titleWrap">
      <img src="@/assets/leftLine.png" alt="">
      <h2>解决方案</h2>
      <img src="@/assets/rightLine.png" alt="">
    </div>
    <p class="content">{{info.solutionInstruction}}</p>

    <!-- 产品介绍 -->
    <div class="productWrap">
      <div class="titleWrap">
        <img src="@/assets/leftLine.png" alt="">
        <h2>产品介绍</h2>
        <img src="@/assets/rightLine.png" alt="">
      </div>
      <div class="productList">
        <div class="item" v-for="item of info.detail" :key="item.id">
          <img :src="item.imgUrl" alt="">
          <p class="content">{{item.abstract}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hospital',
  data() {
    return {
      info: {},
    }
  },
  mounted() {
    this.$post('/api/pc/hospital/business/get').then((res) => {
      // 获取医院智能化信息
      this.info = res
      console.log(res)
    })
  },
  methods: {
    jumpType(id) {
      this.$router.push({
        //核心语句
        path: '/productCategory', //跳转的路径
        query: { id },
      })
    },
  },
}
</script>
<style lang='less' scoped>
p,
h2 {
  margin: 0;
}
.banner {
  min-height: 400px;
  img {
    width: 100%;
    height: 100%;
  }
}
.titleWrap {
  display: flex;
  margin: 50px 23px 0;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 48px;
  }
  img {
    width: 220px;
    height: 18px;
  }
}
.content {
  margin: 60px 26px;
  text-align: justify;
  font-size: 36px;
  line-height: 1.5;
}
.productWrap {
  .titleWrap {
    display: flex;
    margin: 0 23px;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 48px;
    }
    img {
      width: 220px;
      height: 18px;
    }
  }
  .productList {
    .item {
      margin-bottom: 30px;
      border-bottom: 5px solid #e6e6e6;
      img {
        margin: 99px auto;
        display: block;
        height: 390px;
        border-radius: 20px;
      }
      .content {
        margin: 30px 26px;
        font-size: 28px;
        color: #333333;
      }
    }
  }
}
</style>