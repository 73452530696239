<template>
  <div id="newsDetails">
    <div class="banner">
      <img :src="banner" alt="">
    </div>
    <div class="contentWrap">
      <h2 class="title">{{info.title}}</h2>
      <div class="videoList" v-for="item of info.headerVideo" :key="item.videoKey">
        <myVideo :videoSrc='item.videoUrl' :posterUrl='item.coverUrl'></myVideo>
      </div>
      <div class="ql-editor" v-html='info.content'></div>
      <div class="videoList" v-for="item of info.footerVideo" :key="item.videoKey">
        <myVideo :videoSrc='item.videoUrl' :posterUrl='item.coverUrl'></myVideo>
      </div>
    </div>
    
  </div>
</template>

<script>
import myVideo from '../components/assembly/video.vue';
import { quillEditor } from 'vue-quill-editor'; //调用编辑器
import 'quill/dist/quill.snow.css';
export default {
  name: 'newsDetails',
  data:function() {
  return {
    info:{},
    banner:''
    };
  },
  components: {
    myVideo
  },
  mounted() {

    this.$post('/api/pc/common/get/banner')
      .then((res) => { // 获取banner
        this.banner = res.newsInfo.pcImgUrl;
        console.log(res)
      })

    const id = this.$route.query.id;
    this.$post('/api/pc/news/info',{
      id
    })
      .then((res) => { // 获取详情
        this.info = res;
        console.log(res)
      })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>

    .ql-editor  {
      /deep/.ql-size-small{
        font-size: 0.35em;
      }
    }
    .ql-editor{ 
      /deep/.ql-size-large {
        font-size: 1em;
      }
    }
    .ql-editor .ql-size-huge {
        font-size: 2em;
    }
    .banner{
      // min-height: 400px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .title{
        color: #333333;
        font-size: 38px;
        text-align: center;
        margin-top: 0.5rem;
    }
    .contentWrap{
        margin: 0.6rem 0.75rem;
        /deep/img{
          width: 100%;
        }
    }
    .videoList{
      margin: 0.5rem 0;
    }

</style>
