import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.less';

//导入 vue router
import VueRouter from 'vue-router';

import {http} from './api/network'

Vue.prototype.$post = http.post;
Vue.prototype.$get = http.get;

import index from './components/index';
import health from './components/health';
import sterilize from './components/sterilize';
import hospital from './components/hospital';
import companyProfile from './components/companyProfile';
import newsCenter from './components/newsCenter';
import newsDetails from './components/newsDetails';
import productCenter from './components/productCenter';
import productCategory from './components/productCategory';
import productDetails from './components/productDetails';
import contactUs from './components/contactUs';

Vue.use(Vant);
Vue.use(VueRouter);
// 页面配置
const routes = [
  { path: '/', redirect:'/index'},
  { path: '/index', name: 'index', component: index ,meta:{title: '首页'}},
  { path: '/health', name: 'health', component: health ,meta:{title: '智慧健康管理'}},
  { path: '/sterilize', name: 'sterilize', component: sterilize ,meta:{title: '智能消毒杀菌'}},
  { path: '/hospital', name: 'hospital', component: hospital ,meta:{title: '医院业务智能化'}},
  { path: '/companyProfile', name: 'companyProfile', component: companyProfile ,meta:{title: '关于我们'}},
  { path: '/newsCenter', name: 'newsCenter', component: newsCenter ,meta:{title: '新闻中心'}},
  { path: '/newsDetails', name: 'newsDetails', component: newsDetails ,meta:{title: '新闻详情'}},
  { path: '/productCenter', name: 'productCenter', component: productCenter,meta:{title: '产品市中心'}},
  { path: '/productCategory', name: 'productCategory', component: productCategory ,meta:{title: '产品信息'}},
  { path: '/productDetails', name: 'productDetails', component: productDetails ,meta:{title: '产品详情'}},
  { path: '/contactUs', name: 'contactUs', component: contactUs ,meta:{title: '首页'}},

]
const router = new VueRouter({
  routes,
  linkActiveClass:'active'
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  document.documentElement.scrollTop = 0
  next()
})
// router.beforeEach((to, from, next) => {
//   // 让页面回到顶部
//   document.documentElement.scrollTop = 0
//   // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
//  next()
// })


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
