<template>
  <div id="sterilize">
    <div class="banner">
      <img :src="info.solutionUrl" alt="">
    </div>
    <div class="titleWrap">
      <img src="@/assets/leftLine.png" alt="">
      <h2>解决方案</h2>
      <img src="@/assets/rightLine.png" alt="">
    </div>
    <p class="content">{{info.solutionInstruction}}</p>

    <!-- 产品介绍 -->
    <div class="productWrap">
      <div class="titleWrap">
        <img src="@/assets/leftLine.png" alt="">
        <h2>产品介绍</h2>
        <img src="@/assets/rightLine.png" alt="">
      </div>
      <div class="productList">
        <div class="item" v-for="item of info.detail" :key='item.id'>
          <img :src="item.imgUrl" alt="" @click="jumpType(item.jumpUrl,item.id)">
        </div>
      </div>
    </div>

    <!-- 应用案例 -->
    <div class="caseWrap">
      <div class="titleWrap">
        <img src="@/assets/leftLine.png" alt="">
        <h2>应用案例</h2>
        <img src="@/assets/rightLine.png" alt="">
      </div>
      <img class="caseImg" v-if="info.case" :src="info.case.imgUrl" alt="" @click="jumpType(info.case.jumpUrl,null)">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sterilize',
  data() {
    return {
      info: {},
    }
  },
  mounted() {
    this.$post('/api/pc/kill/germ/get').then((res) => {
      // 获取智能消杀信息
      this.info = res
      console.log(res)
    })
  },
  methods: {
    jumpType(url, id) {
      if (url) {
        window.location = url
        return
      }
      this.$router.push({
        //核心语句
        path: '/productCategory', //跳转的路径
        query: { id },
      })
    },
  },
}
</script>
<style lang='less' scoped>
p,
h2 {
  margin: 0;
}
.banner {
  min-height: 500px;
  background: #011831;
  img {
    width: 100%;
    height: 100%;
  }
}
.titleWrap {
  display: flex;
  margin: 50px 23px 0;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 48px;
  }
  img {
    width: 220px;
    height: 18px;
  }
}
.content {
  margin: 60px 27px;
  text-align: justify;
  font-size: 36px;
  line-height: 1.5;
}
.productWrap {
  .titleWrap {
    display: flex;
    margin: 0 23px;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 48px;
    }
    img {
      width: 220px;
      height: 18px;
    }
  }
  .productList {
    margin: 50px 95px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .item {
      margin: 50px;
      width: 560px;
      height: 390px;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        display: block;
      }
    }
  }
}

.caseWrap {
  margin-bottom: 59px;
  .titleWrap {
    display: flex;
    margin: 102px 23px 0;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 48px;
    }
    img {
      width: 220px;
      height: 18px;
    }
  }
  .caseImg {
    margin-top: 60px;
    width: 100%;
  }
}
</style>