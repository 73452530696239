<template>
  <div id="newsCenter">
    <div class="banner">
      <img :src="banner" alt="">
    </div>
    <div class="newsList">
        <div class="item" v-for="item of newsList" :key="item.id"  @click="toDetails(item.jumpUrl,item.id)">
            <div class="bg" :style="{backgroundImage:'url('+item.coverUrl+')'}">
                <div class="timeWrap">
                    <p class="date">{{item.monthDay}}</p>
                    <p class="year">{{item.year}}</p>
                </div>
            </div>
            <p class="title">{{item.title}}</p>
            <p class="content">{{item.abstract}}</p>
        </div>
    </div>

    <p class="moreBtn" @click="getList()" v-if="page*10<total">查看更多</p>
  </div>
</template>

<script>
export default {
  name: 'newsCenter',
  data:function() {
  return {
      newsList:[],
      total:null,//总数量
      page:1,// 页数
      banner:''
    };
  },
  mounted() {
      this.$post('/api/pc/common/get/banner')
        .then((res) => { // 获取banner
          this.banner = res.news.appImgUrl;
        })
      this.$post('/api/pc/news/list',{
        page:this.page,
      })
      .then((res) => { // 获取新闻列表
        console.log(res)
        this.newsList = res.data;
        this.total = res.total;
      })
  },
  methods:{
    toDetails(url,id){
      if(url){
        window.open(url,'_blank');
        return;
      }
      this.$router.push({ //核心语句
        path:'/newsDetails', //跳转的路径
        query: { id }
      })
    },
    getList(){ // 获取新闻列表
      this.page = this.page+1;
      this.$post('/api/pc/news/list',{
        page:this.page,
      })
      .then((res) => { // 获取新闻列表
        console.log(res)
        this.newsList = this.newsList.concat(res.data);
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='less' scoped>
    .banner{
      // height: 714px;
      // background:#000 ;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .newsList{
        margin: 1.25rem 0.63rem;
        .item{
            margin-bottom: 1.63rem;
            font-size: #333333;
            .bg{
                position: relative;    
                // height: 11.06rem;
                width: 709px;
                height: 450px;
                margin: 0 auto;
                background: #333333;
                background-size: 100% 100%;
                .timeWrap{
                    position: absolute;
                    width: 3.47rem;
                    height: 2.84rem;
                    background: #0D51A4;
                    bottom: 0;
                    right: 0;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-end;
                    padding-right: 0.47rem;
                    .date{
                        font-size: 1.13rem;
                    }
                    .year{
                        font-size: 0.75rem;
                    }
                }
            }
            .title{
                font-size: 0.9rem;
                margin: 1.22rem 0 0.69rem;
                color: #0D51A4;
            }
            .content{
                font-size: 0.7rem;
            }
        }
    }
    .moreBtn{
        width: 6.07rem;
        height: 1.83rem;
        border: 0.02rem solid #0D51A4;
        margin: 0 auto;
        text-align: center;
        font-size: 0.88rem;
        line-height: 1.83rem;
        color: #0D51A4;
        margin-bottom: 1.94rem;
    }
</style>
