<template>
  <div id="app">
    <!-- 微信打开商城 -->
    <div id="show">
      <div class="cover"></div>
      <img class="wxImg" src="@/assets/live_weixin.png" alt="">
      <span class="close" title="关闭" @click="close()">×</span>
    </div>

    <!-- 微信打开商城END -->
    <header>
      <div class="navWrap">
        <img class="logo" src="@/assets/logo.png" alt="" @click="toIndex($route.fullPath)">
        <h2 class="myTitle">{{pageTitle($route.fullPath)}}</h2>
        <img class="navBtn" src="@/assets/navBtn.png" alt="" @click="showPopup">
        <van-popup v-model="show" position="right" :style="{ height: '100%',width:'60%' }">
          <router-link to="/index">
            <p @click="closeNav()">首页</p>
          </router-link>
          <router-link to="/health">
            <p @click="closeNav()">智慧健康管理</p>
          </router-link>
          <router-link to="/sterilize">
            <p @click="closeNav()">智能消毒杀菌</p>
          </router-link>
          <router-link to="/hospital">
            <p @click="closeNav()">医院业务智能化</p>
          </router-link>
          <router-link to="/newsCenter">
            <p @click="closeNav()">新闻中心</p>
          </router-link>
          <router-link to="/companyProfile">
            <p @click="closeNav()">关于我们</p>
          </router-link>
          <a>
            <p @click="jumpOutside()">网上商城</p>
          </a>
          <a target="_blank" href="http://www.usopptech.com/download.html">
            <p>软件下载</p>
          </a>
        </van-popup>
      </div>
    </header>
    <div id="indexCenterContent">
      <router-view />
    </div>
    <footer v-if="notIndex($route.fullPath)">
      <div class="enterpriseInfo">
        <div class="content">
          <p>电话：{{info.phone}}</p>
          <p>邮箱：{{info.email}}</p>
          <p>地址：{{info.address}}</p>
          <p>
            <a target="_blank" href="https://beian.miit.gov.cn">京ICP备17066860号</a>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802025206"> 京公网安备11010802025206号</a>
          </p>
        </div>
        <div class="qrcodeWrap">
          <div class="item">
            <img :src="info.qrCodeUrl" alt="">
            <p>微信公众号</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      show: false,
      info: {},
      onlineMall: '',
      appUrl: 'm.usopptech.com', // 移动端页面地址
      webUrl: 'www.usopptech.com', // web端页面地址
    }
  },
  mounted() {
    if (this._isMobile()) {
      console.log('手机端')
    } else {
      console.log('pc端')
      const url = window.location.href.replace(this.appUrl, this.webUrl)
      window.location.replace(url) // 跳转到移动端对应页面
    }

    this.$post('/api/pc/common/get/company/info').then((res) => {
      // 获取公司信息
      console.log(res)
      this.info = res
    })
  },

  methods: {
    // 获取页面标题
    pageTitle(path) {
      console.log(path)
      if (path == '/index') {
        return '北京乌索普科技发展有限公司'
      }
      if (path == '/health') {
        return '智慧健康管理'
      }
      if (path == '/sterilize') {
        return '智能消毒杀菌'
      }
      if (path == '/hospital') {
        return '医院业务智能化'
      }
      if (path == '/newsCenter') {
        return '新闻中心'
      }
      if (path == '/companyProfile') {
        return '关于我们'
      }
    },
    // 判断是否首页
    notIndex(path) {
      if (path == '/index') {
        return false
      }
      return true
    },
    // 跳转到首页
    toIndex(path) {
      if (path == '/index') {
        return false
      }
      this.$router.push({
        //核心语句
        path: '/index', //跳转的路径
      })
    },
    showPopup() {
      this.show = true
    },
    closeNav() {
      this.show = false
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    jumpOutside() {
      this.show = false
      let ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        const cover = document.getElementById('show')
        cover.style.display = 'block'
        // 微信环境打开网上商城弹窗
        console.log('微信中')
        return
      }
      window.open(this.info.onlineMall, '_blank')
    },
    // 关闭遮层
    close() {
      const cover = document.getElementById('show')
      cover.style.display = 'none'
    },
  },
}
</script>

<style lang="less">
h2,
p {
  margin: 0;
}
#show {
  display: none;
}
.cover {
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  z-index: 9999;
  opacity: 0.6;
}
.wxImg {
  width: 100%;
  position: absolute;
  z-index: 10000;
  top: 30px;
}
.close {
  position: absolute;
  z-index: 10000;
  font-size: 60px;
  top: 30px;
  color: #fff;
}
.navWrap {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 0;
  justify-content: space-between;
  position: absolute;
  z-index: 999;
  .myTitle {
    font-size: 24px;
    color: #fff;
    margin-left: auto;
    margin-right: 20px;
  }
  .logo {
    width: 5.09rem;
    height: 1.63rem;
    margin-left: 40px;
  }
  .navBtn {
    width: 1.88rem;
    height: 1.03rem;
    margin-right: 40px;
  }
  a {
    color: #333333;
    display: block;
    border-bottom: 0.02rem solid #747474;
    margin: 0 25px;
  }
  p {
    padding: 60px 0;
    text-indent: 1rem;
    font-size: 1rem;
  }
}

.van-swipe__indicator {
  width: 0.4rem !important;
  height: 0.4rem !important;
  background-color: #333333 !important;
}
.van-swipe__indicator--active {
  background-color: #0d51a4 !important;
}

footer {
  background: #333333;
  color: #d5d5d5;
  padding: 0.2rem 0.69rem;
  .bottomNav {
    font-size: 0.75rem;
    p {
      padding: 0.53rem 0;
      border-bottom: 0.03rem solid #d5d5d5;
      a {
        color: #d5d5d5;
      }
    }
  }
  .enterpriseInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 1.34rem;
    .content {
      font-size: 0.75rem;
      p {
        margin-bottom: 27px;
      }
      a {
        color: #d5d5d5;
      }
    }
    .qrcodeWrap {
      display: flex;
      align-items: flex-end;
      margin-bottom: 15px;
      .item {
        display: flex;
        align-items: center;
        height: 128px;
        img {
          width: 106px;
          height: 104px;
        }
        p {
          width: 12px;
          margin-left: 8px;
          font-size: 12px;
          transform: scale(0.6);
          transform-origin: 0;
        }
      }
    }
  }
}
</style>
