<template>
  <div id="productCenter">
    <div class="typeShow">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color='#0D51A4'>
          <van-swipe-item @click="toProductCategory(item.productTypeId)" v-for="item of typeList" :key="item.productTypeId">
              <div class="bg" :style="{backgroundImage:'url('+item.imgUrl+')'}">
                  <!-- <h2 class="title">{{item.productTypeName}}</h2> -->
                  <p class="content">{{item.instruction}}</p>
              </div>
          </van-swipe-item>
        </van-swipe>
    </div>
    <div class="productList">
        <h2 class="pageTitle">产品介绍</h2>
        <div class="item" v-for="item of productList" :key="item.id" @click="toDetails(item.id)">
            <img :src="item.coverUrl" alt="">
            <div class="contentWrap">
                <p class="title">{{item.title}}</p>
                <p class="content">{{item.abstract}}</p>
            </div>
        </div>
    </div>

    <p class="moreBtn" v-if="page*10<total" @click="more()">查看更多</p>
  </div>
</template>

<script>


export default {
  name: 'productCenter',
  data:function() {
  return {
      typeList:[],//类别列表
      page:1,// 产品页码
      total:null,// 产品总数
      productList:[] //产品列表
    };
  },
  mounted() {
    this.$post('/api/pc/product/list')
        .then((res) => { // 获取产品列表
            console.log(res)
            // this.productListSpinning = false;
            this.productList = res.data;
            this.total = res.total;
        })
    this.$post('/api/pc/product/get/type')
      .then((res) => { // 获取产品类别
        console.log(res)
        // this.typeSpinning = false;
        this.typeList = res;
        
      })
  },
  methods:{
    toProductCategory(id){
      this.$router.push({ //核心语句
        path:'/productCategory', //跳转的路径
        query:{           //路由传参时push和query搭配使用 ，作用时传递参数
          id, 
        }
      })
    },
    toDetails(id){
        this.$router.push({ //核心语句
            path:'/productDetails', //跳转的路径
            query:{           //路由传参时push和query搭配使用 ，作用时传递参数
              id , 
            }
        })
    },
    more(){
      this.page = this.page + 1;
      this.$post('/api/pc/product/list'),{
        page:this.page
      }
      .then((res) => { // 获取产品列表
        console.log(res)
        this.productList = res.data;
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='less' scoped>

    .typeShow{
        // height: 5rem;
        padding: 1.25rem 0.63rem;
        .van-swipe-item{
            height: 16rem;
            position: relative;
        }
        .bg{
            height: 12.13rem;
            background: #0D51A4;
            background-size: 100% 100%;
            .title{
                text-align: center;
                font-size: 1.13rem;
                color: #FFFFFF;
                text-align: center;
                padding-top: 3.84rem;
            }
            .content{
                width: 11.11rem;
                height: 4.37rem;
                font-size: 0.75rem;
                position: absolute;
                left: 2.19rem;
                bottom: 1.97rem;
                background: #FFFFFF;
                text-align: center;
                padding: 1.66rem 3.4rem 0;
                box-shadow: 0.03rem 0.03rem 0.03rem 0.03rem rgba(0, 0, 0, 0.22);
            }
        }
    }
    .productList{
        margin: 1.25rem 0.63rem;
        .pageTitle{
            font-size: 0.94rem;
            color: #333333;
            text-align: center;
            margin: 1.25rem 0;
        }
        .item{
            margin-bottom: 1.94rem;
            height: 6.04rem;
            background: #fff;
            display:flex;
            overflow: hidden;
            img{
                width: 9.53rem;
                height: 6.04rem;
                background: blue;
                flex-shrink: 0;
            }
            .contentWrap{
                margin-left: 0.66rem;
                color:#333333;
                .title{
                    font-size: 0.75rem;
                    margin-bottom: 0.63rem;
                    font-size: 0.69rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .content{
                    font-size: 0.69rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4; //需要控制的文本行数
                    overflow: hidden;
                }
            }
        }
    }
    .moreBtn{
        width: 6.07rem;
        height: 1.83rem;
        border: 0.02rem solid #0D51A4;
        margin: 0 auto;
        text-align: center;
        font-size: 0.88rem;
        line-height: 1.83rem;
        color: #0D51A4;
        margin-bottom: 1.94rem;
    }
</style>
