<template>
  <div id="productCategory">

    <div class="banner">
      <img :src="banner" alt="">
      <h2 class="pageTitle">{{title}}</h2>
    </div>
    <div class="productList">
      <div class="item" v-for="item of productList" :key="item.productId" @click="toDetails(item.productId)" :style="{backgroundImage:'url('+item.coverUrl+')'}">
        <h2>{{item.title}}</h2>
      </div>
    </div>

    <p class="moreBtn" v-if="page*10<total">查看更多</p>
  </div>
</template>

<script>
export default {
  name: 'productCategory',
  data:function() {
  return {
      type:null,//产品类别
      productList:[],
      total:null,
      page:1,
      banner:'',
      title:''
    };
  },
  mounted() {
      const id = this.$route.query.id?this.$route.query.id:null;
      this.$post('/api/pc/product/list',{
        listId:id
        })
      .then((res) => { // 获取产品列表
        console.log(res)
        this.total = res.total;
        this.productList = res.data;
        this.title = res.listName;
      })

      this.$post('/api/pc/common/get/banner')
      .then((res) => { // 获取banner
        this.banner = res.productList.appImgUrl;
        console.log(res)
      })
  },
  methods:{
      more(){
      this.page = this.page + 1;
      this.$post('/api/pc/product/list'),{
        page:this.page
      }
      .then((res) => { // 获取产品列表
        console.log(res)
        this.productList = res.data;
      })
    },
    toDetails(id){
      this.$router.push({ //核心语句
          path:'/productDetails', //跳转的路径
          query:{           //路由传参时push和query搭配使用 ，作用时传递参数
          id, 
          }
      })
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='less' scoped>
    .banner{
      position: relative;
      img{
        width: 100%;
        height: 100%;
      }
      .pageTitle{
          position: absolute;
          width: 100%;
          top: 40%;
          font-size: 40px;
          text-align: center;
          letter-spacing: 15px;
          color: #fff;
      }
    }
    .productList{
        margin: 1.25rem 1.8rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item{
          width:324.9px;
          height: 431.3px;
          background-size: 100% 100%;
          border-radius: 20px;
          margin-bottom:60px ;
          position: relative;
          overflow: hidden;
            h2{
                height: 81px;
                line-height: 81px;
                font-size: 30px;
                text-align: center;
                color: #FFFFFF;
                background: #A6A6A6;
                width: 100%;
                position: absolute;
                bottom: 0;
            }
        }
    }
    .moreBtn{
        width: 6.07rem;
        height: 1.83rem;
        border: 0.02rem solid #0D51A4;
        margin: 0 auto;
        text-align: center;
        font-size: 0.88rem;
        line-height: 1.83rem;
        color: #0D51A4;
        margin-bottom: 1.94rem;
    }
</style>
