<template>
  <div id="companyProfile">
    <div class="banner">
      <img :src="banner" alt="">
    </div>
    <div class="titleWrap">
      <!-- <img
        src="@/assets/leftLine.png"
        alt=""
      > -->
      <h2>公司简介</h2>
      <!-- <img
        src="@/assets/rightLine.png"
        alt=""
      > -->
    </div>
    <myVideo v-if="info.introduceVideoUrl" id="mse" :videoSrc='info.introduceVideoUrl' :posterUrl='info.introduceImgUrl'></myVideo>
    <img class="showImg" :src="info.introduceImgUrl" alt="">
    <div class="contentWrap">
      <p class="profile">{{info.introduction}}</p>
    </div>

    <div class="infoWrap">
      <div class="item">
        <div class="itemBg">
          <img :src="info.missionImgUrl" alt="">
        </div>
      </div>
      <div class="item">
        <div class="itemBg">
          <img :src="info.visionImgUrl" alt="">
        </div>
      </div>
      <div class="item">
        <div class="itemBg">
          <img :src="info.valueImgUrl" alt="">
        </div>
      </div>
    </div>
    <div class="qualifications">
      <div class="titleWrap">
        <!-- <img src="@/assets/leftLine.png" alt=""> -->
        <h2>企业资质</h2>
        <!-- <img src="@/assets/rightLine.png" alt=""> -->
      </div>
      <div class="qualificationsList">
        <div class="item" v-for="item of qualification" :key="item.id">
          <img :src="item.imgUrl+'?imageMogr2/auto-orient'" alt="">
        </div>
      </div>
    </div>

    <div class="titleWrap">
      <!-- <img src="@/assets/leftLine.png" alt=""> -->
      <h2>知识产权</h2>
      <!-- <img src="@/assets/rightLine.png" alt=""> -->
    </div>

    <div class="property">
      <img :src="info.propertyImgUrl" alt="">
    </div>
  </div>
</template>

<script>
import myVideo from '../components/assembly/video.vue'
export default {
  name: 'companyProfile',
  data: function () {
    return {
      info: {}, //企业信息
      qualification: [], // 企业资质
      banner: '',
    }
  },
  components: {
    myVideo,
  },
  mounted() {
    this.$post('/api/pc/common/get/banner').then((res) => {
      // 获取banner
      this.banner = res.aboutUs.appImgUrl
    })
    this.$post('/api/pc/company/introduce/get/basic/info').then((res) => {
      // 获取企业信息
      console.log(res)
      this.info = res
    })
    this.$post('/api/pc/company/introduce/get/qualification').then((res) => {
      // 获取企业资质
      console.log(res)
      this.qualification = res
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.banner {
  // min-height: 600px;
  // background: #262D35;
  img {
    width: 100%;
    height: 100%;
  }
}
.titleWrap {
  display: flex;
  margin: 60px 20px;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 48px;
    margin: 0;
  }
  img {
    width: 220px;
    height: 18px;
  }
}
// .titleWrap{
//     display: flex;
//     color: #333333;
//     align-items: flex-end;
//     margin: 1.22rem 0.63rem;
//     .bigSize{
//         font-size: 0.94rem;
//     }
//     .smallSize{
//         font-size: 0.69rem;
//     }
// }
#mse {
  // width: 100%;
  height: 14rem !important;
  background: #fff;
  margin: 0 20px;
  z-index: 99;
}
.showImg {
  width: 100%;
}
.profile {
  margin: 1.22rem 1.13rem 1.88rem 0.66rem;
  white-space: pre-wrap;
  text-align: justify;
  word-break: break-all;
}
// .profile::after{
//   content:'';
//   width:100%;
//   display: inline-block;
// }

.infoWrap {
  margin: 0 2.06rem 1.25rem 1.66rem;
  color: #ffffff;
  .item {
    height: 11.68rem;
    background: #ffffff;
    border: 0.05rem solid #bfbfbf;
    margin-bottom: 1rem;
    .itemBg {
      margin: 0.55rem;
      height: 10.49rem;
      background: #fff;
      text-align: center;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.qualifications {
  // padding: 0 0.59rem;
  margin-bottom: 2rem;
  h2 {
    font-size: 0.94rem;
    // color: #333333;
    text-align: center;
    margin-bottom: 1.22rem;
  }
  .titleWrap {
    display: flex;
    margin: 60px 20px;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 48px;
      margin: 0;
    }
    img {
      width: 220px;
      height: 18px;
    }
  }
  .qualificationsList {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .item {
      width: 226px;
      height: 282px;
      background: #01081b;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.property {
  img {
    width: 100%;
  }
}
</style>
