<template>
  <div id="index" v-cloak>
    <div class="banner-swiper-container">
      <div class="swiper-wrapper">
        <div v-for="item of bannerList" :key="item.id" class="swiper-slide">
          <img :src="item.appImgUrl" alt="" @click="jumpOutside()">
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination" />
      <!-- Add Arrows -->
      <div class="swiper-button-next">
        <img src="@/assets/right.png" alt="">
      </div>
      <div class="swiper-button-prev">
        <img src="@/assets/left.png" alt="">
      </div>
    </div>

    <div class="bottom">
      <div class="infoWrap">
        <div>
          <p>电话：{{info.phone}}</p>
          <a target="_blank" href="https://beian.miit.gov.cn">
            <p>京ICP备17066860号</p>
          </a>
        </div>
        <div>
          <p>邮箱：{{info.email}}</p>

          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802025206">
            <p>京公网安备 11010802025206号</p>
          </a>

        </div>
        <div class='qrcodeWrap'>
          <div class="item">
            <img :src="info.qrCodeUrl" alt="">
            <p>微信公众号</p>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import 'swiper/swiper-bundle.min.css'
import { Swiper, Navigation, Autoplay, EffectFade, Pagination } from 'swiper'
export default {
  name: 'index',
  data: function () {
    return {
      onlineMall: '',
      bannerList: [], //banner犁碑坳
      nowIndex: '',
      info: {},
      // productIndex:0,//当前产品下标
      // nowProduct:[],// 产品信息列表
      // newList:[],// 新闻列表
      // partnerList:[]//合作伙伴列表
    }
  },
  mounted() {
    this.$post('/api/pc/common/get/company/info').then((res) => {
      // 获取公司信息
      console.log(res)
      this.info = res
    })

    this.$post('/api/pc/home/get/banner').then((res) => {
      // 获取banner
      const that = this
      this.bannerSpinning = false
      res.pop() // 暂时删除职业发展 2021/1/30
      this.bannerList = res
      console.log(res)
      setTimeout(() => {
        Swiper.use([Navigation])
        Swiper.use([Autoplay])
        Swiper.use([EffectFade])
        Swiper.use([Pagination])
        new Swiper('.banner-swiper-container', {
          autoplay: true,
          effect: 'fade',
          loop: true,
          loopFillGroupWithBlank: true,
          observer: true,
          observeParents: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          on: {
            init: function (swiper) {
              setTimeout(() => {
                const dom = document.getElementsByClassName('swiper-pagination-bullet-active')[0]
                dom.innerText = '智慧健康管理'
              }, 1)
            },
            slideChange: function () {
              // 清除样式
              const otherDom = document.getElementsByClassName('swiper-pagination-bullet')
              otherDom.forEach((item) => {
                item.innerText = ''
              })
            },
            slideChangeTransitionEnd: function (swiper) {
              // 为当前图片添加样式
              const dom = document.getElementsByClassName('swiper-pagination-bullet-active')[0]
              const index = swiper.realIndex
              that.nowIndex = index
              console.log(index)
              if (dom) {
                switch (index) {
                  case 0:
                    dom.innerText = '智慧健康管理'
                    break
                  case 1:
                    dom.innerText = '智能消毒杀菌'
                    break
                  case 2:
                    dom.innerText = '医院业务智能化'
                    break
                  case 3:
                    dom.innerText = '新闻中心'
                    break
                  case 4:
                    dom.innerText = '关于我们'
                    break
                  case 5:
                    dom.innerText = '职业发展'
                    break
                }
              }
            },
          },
        })
      }, 500)
    })
  },
  methods: {
    jumpOutside() {
      // banner跳转外部链接
      const index = this.nowIndex
      let page = ''
      if (index == 0) {
        page = '/health'
      }
      if (index == 1) {
        page = '/sterilize'
      }
      if (index == 2) {
        page = '/hospital'
      }
      if (index == 3) {
        page = '/newsCenter'
      }
      if (index == 4) {
        page = '/companyProfile'
      }
      this.$router.push({
        //核心语句
        path: page, //跳转的路径
      })
    },

    toDetails(id) {
      this.$router.push({
        //核心语句
        path: '/newsDetails', //跳转的路径
        query: {
          //路由传参时push和query搭配使用 ，作用时传递参数
          id,
        },
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
[v-cloak] {
  display: none;
}
p {
  margin: 0;
}
a {
  color: #d0d0d0;
}
#index {
  position: relative;
  height: 100%;
}
.banner-swiper-container {
  width: 100%;
  height: calc(100vh);
  // height: 937px;
  overflow: hidden;
  .swiper-pagination {
    display: flex;
    align-items: center;
    bottom: 240px;
    position: relative;
    justify-content: space-around;
    /deep/.swiper-pagination-bullet {
      margin: 0 20px;
      background: #fff;
      opacity: 1;
      position: relative;
    }
    /deep/.swiper-pagination-bullet::after {
      content: '';
      height: 0.05rem;
      width: 3.21rem;
      background: hsla(0, 0%, 100%, 0.5);
      position: absolute;
      top: 50%;
      left: 100%;
    }
    /deep/.swiper-pagination-bullet-active {
      margin: 0 20px;
      background: none;
      opacity: 1;
      height: 50px;
      width: 200px;
      display: block;
      border-radius: 20px;
      border: 2px solid #fff;
      font-size: 16px;
      line-height: 60px;
      position: relative;
      color: #fff;
    }
    /deep/.swiper-pagination-bullet-active::after {
      content: '';
      height: 0.05rem;
      width: 3.22rem;
      background: hsla(0, 0%, 100%, 0.5);
      position: absolute;
      top: 50%;
      left: 100%;
    }
    /deep/.swiper-pagination-bullet:last-child::after {
      width: 2.28rem;
    }
    /deep/.swiper-pagination-bullet:first-child::before {
      content: '';
      height: 0.05rem;
      width: 2.28rem;
      background: hsla(0, 0%, 100%, 0.5);
      position: absolute;
      top: 50%;
      right: 100%;
    }
    // 用于宽屏幕手机兼容
    @media screen and (min-width: 375px) {
      /deep/.swiper-pagination-bullet::after {
        width: 3.6rem;
      }
      /deep/.swiper-pagination-bullet-active::after {
        width: 3.6rem;
      }
    }
  }
  .swiper-slide {
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: auto;
      min-height: 100%;
      transition: 1s linear 1s;
      transform: scale(1.1, 1.1);
      filter: blur(15px);
      z-index: -1;
    }
  }
  .swiper-slide-active img {
    transition: 1s linear;
    transform: scale(1);
    filter: blur(0);
    z-index: 999999;
  }
  // .swiper-pagination::before {
  //   content: '';
  //   position: absolute;
  //   bottom: 0.65rem;
  //   left: 0;
  //   width: 2.2rem;
  //   height: 0.05rem;
  //   background: hsla(0, 0%, 100%, 0.5);
  //   z-index: -1;
  // }
  // .swiper-pagination::before {
  //   content: '';
  //   height: 0.05rem;
  //   width: 2.28rem !important;
  //   background: hsla(0, 0%, 100%, 0.5);
  //   position: absolute;
  //   top: 50%;
  //   left: 100%;
  // }
  .swiper-button-next {
    top: 80%;
    left: 198px;
    img {
      width: 61px;
      height: 27px;
    }
  }
  .swiper-button-prev {
    top: 80%;
    left: 65px;
    img {
      width: 61px;
      height: 27px;
    }
  }
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    display: none !important;
  }
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    display: none !important;
  }
}

.bottom {
  position: absolute;
  bottom: 0;
  z-index: 99;
  font-size: 20px;
  color: #d0d0d0;
  width: 100%;
  .infoWrap {
    display: flex;
    padding: 16px 23px;
    justify-content: space-between;
    align-items: flex-end;
  }
  .qrcodeWrap {
    display: flex;
    .item {
      // width: 78px;
      img {
        height: 94px;
        width: 94px;
        display: block;
        margin: 0 auto;
      }
      p {
        white-space: nowrap;
        width: 100%;
        font-size: 20px;
        text-align: center;
        // transform-origin: 15% 65%;
        transform: scale(0.7);
      }
    }
  }
}
</style>
